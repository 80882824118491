export const listingsConstants = {
  GET_LISTINGS_REQUEST: "GET_LISTINGS_REQUEST",
  GET_LISTINGS_SUCCESS: "GET_LISTINGS_SUCCESS",
  GET_LISTINGS_FAILURE: "GET_LISTINGS_FAILURE",
  GET_MY_LISTINGS_REQUEST: "GET_MY_LISTINGS_REQUEST",
  GET_MY_LISTINGS_SUCCESS: "GET_MY_LISTINGS_SUCCESS",
  GET_MY_LISTINGS_FAILURE: "GET_MY_LISTINGS_FAILURE",
  SET_LISTING_INDEX: "SET_LISTING_INDEX",
  SET_BOUNDS: "SET_BOUNDS",
  SET_PLACE_VALUES: "SET_PLACE_VALUES",
  SET_FILTERS: "SET_FILTERS",
  REFRESH_PAGE_COUNT: "REFRESH_PAGE_COUNT",
  GET_CONTACT_NUMBER_SUCCESS: "GET_CONTACT_NUMBER_SUCCESS",
  GET_CONTACT_NUMBER_REQUEST: "GET_CONTACT_NUMBER_REQUEST",
  GET_CONTACT_NUMBER_FAILURE: "GET_CONTACT_NUMBER_FAILURE"
};
