export const authConstants = {
  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
  REQUEST_OTP_REQUEST: "REQUEST_OTP_REQUEST",
  REQUEST_OTP_SUCCESS: "REQUEST_OTP_SUCCESS",
  REQUEST_OTP_FAILURE: "REQUEST_OTP_FAILURE",
  PASSWORD_SIGNIN_REQUEST: "PASSWORD_SIGNIN_REQUEST",
  PASSWORD_SIGNIN_SUCCESS: "PASSWORD_SIGNIN_SUCCESS",
  PASSWORD_SIGNIN_FAILURE: "PASSWORD_SIGNIN_FAILURE",
  REFRESH_REQUEST: "REFRESH_REQUEST",
  REFRESH_SUCCESS: "REFRESH_SUCCESS",
  REFRESH_FAILURE: "REFRESH_FAILURE",
  LOGOUT: "LOGOUT"
};
