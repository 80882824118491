export const postingConstants = {
  SET_POSTING_DETAILS: "SET_POSTING_DETAILS",
  SET_PROPERTY_ADDRESS: "SET_PROPERTY_ADDRESS",
  SET_PROPERTY_PRICE: "SET_PROPERTY_PRICE",
  SET_PROPERTY_ADDRESS_CORDINATES: "SET_PROPERTY_ADDRESS_CORDINATES",
  UPLOAD_IMAGES_SUCCESS: "UPLOAD_IMAGES_SUCCESS",
  UPLOAD_IMAGES_REQUEST: "UPLOAD_IMAGES_REQUEST",
  UPLOAD_IMAGES_FAILURE: "UPLOAD_IMAGES_FAILURE",
  SET_IMAGES: "SET_IMAGES",
  POSTING_REQUEST: "POSTING_REQUEST",
  POSTING_SUCCESS: "POSTING_SUCCESS",
  POSTING_FAILURE: "POSTING_FAILURE",
  PAYMENT_FAILURE: "PAYMENT_FAILURE",
  PAYMENT_REQUEST: "PAYMENT_REQUEST",
  DELETE_POSTING_REQUEST: "DELETE_POSTING_REQUEST",
  DELETE_POSTING_SUCCESS: "DELETE_POSTING_SUCCESS",
  DELETE_POSTING_FAILURE: "DELETE_POSTING_FAILURE",
  CONTACT_FORM_REQUEST: "CONTACT_FORM_REQUEST",
  CONTACT_FORM_SUCCESS: "CONTACT_FORM_SUCCESS",
  CONTACT_FORM_FAILURE: "CONTACT_FORM_FAILURE",
  SET_EDIT_POSTING_DETAILS: "SET_EDIT_POSTING_DETAILS",
  DELETE_IMAGE_REQUEST: "DELETE_IMAGE_REQUEST",
  DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_FAILURE: "DELETE_IMAGE_FAILURE",
  SET_PLAN: "SET_PLAN",
  RESET_POSTING_DETAILS: "RESET_POSTING_DETAILS"
};
